<template>
  <v-dialog
    v-model="dialogPost"
    @click:outside="$emit('update:dialogPost', false)"
    @keydown.esc="$emit('update:dialogPost', false)"
    max-width="1200px"
  >
    <v-form @submit.prevent ref="form" v-model="validForm" lazy-validation>
      <v-card>
        <v-card-title class="pa-4 accent">
          <v-icon color="primary" left>mdi-square-edit-outline</v-icon>
          {{ edit ? "Editar" : "Adicionar" }} Post

          <v-spacer></v-spacer>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="">
          <v-row>
            <v-col cols="12" md="2">
              <v-card
                flat
                class="fill-height d-flex align-center justify-center"
              >
                <v-card flat v-if="thumbnail">
                  <v-img :src="thumbnail" contain></v-img>
                </v-card>
                <div v-else class="fill-height" style="width: 100%">
                  <vue-dropzone
                    ref="myVueDropzone"
                    :id="postInvalido ? 'dropzone-error' : 'dropzone'"
                    :class="{ hoverFile: hoverFile }"
                    :use-custom-dropzone-options="true"
                    :options="dropzoneOptions"
                    @vdropzone-file-added="fileAdded"
                    @vdropzone-drag-enter="hoverFile = true"
                    @vdropzone-drag-over="hoverFile = true"
                    @vdropzone-drag-leave="hoverFile = false"
                    :useCustomSlot="true"
                    :include-styling="false"
                  >
                    <div class="text-center">
                      <v-icon size="80" color="info"> mdi-image-plus </v-icon>
                      <h3 class="text-subtitle-1">Arrastar e soltar</h3>
                      <div class="text-caption">Clique para selecionar</div>
                    </div>
                  </vue-dropzone>
                </div>
              </v-card>
            </v-col>
            <v-col cols="12" md="10">
              <v-row dense class="px-4">
                <!-- Destaque -->
                <v-col cols="12">
                  <v-text-field dense readonly value="Destaque:">
                    <template v-slot:append>
                      <v-switch
                        v-model="post.destaque"
                        :false-value="false"
                        :true-value="true"
                        class="mt-0 pt-0"
                        hide-details
                      >
                      </v-switch>
                    </template>
                  </v-text-field>
                </v-col>

                <!-- Conteudo -->
                <v-col cols="12">
                  <v-textarea
                    :class="postInvalido ? 'error-input' : ''"
                    v-model="post.conteudo"
                    label="Mensagem"
                    dense
                  ></v-textarea>
                </v-col>

                <!-- Notificar -->
                <v-col v-if="post.destaque === true" cols="12">
                  <v-text-field dense readonly value="Notificar:">
                    <template v-slot:append>
                      <v-switch
                        v-model="post.notificar"
                        :false-value="false"
                        :true-value="true"
                        class="mt-0 pt-0"
                        hide-details
                      >
                      </v-switch>
                    </template>
                  </v-text-field>
                </v-col>
                <!-- Notificar Texto -->
                <v-col v-if="post.destaque === true" cols="12">
                  <v-text-field
                    v-model="post.notificar_texto"
                    label="Mensagem Notificaçao"
                    required
                    :rules="post.notificar === true ? formRules : [true]"
                    :disabled="!post.notificar ? true : false"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="py-3">
          <v-btn text @click="$emit('update:dialogPost', false)">
            Cancelar
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            @click="edit ? updatePost() : createPost()"
            :disabled="!validForm"
            :color="edit ? 'save' : 'xbColor'"
            class="white--text"
          >
            <v-icon left>{{ edit ? "mdi-content-save" : "mdi-plus" }}</v-icon>
            {{ edit ? "salvar" : "Adicionar" }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import vue2Dropzone from "vue2-dropzone";
import { postPost, putPost } from "@/api/admin/posts.js";
import { create as postFeed } from "@/api/admin/feed.js";
import { inputRequired } from "@/plugins/utils.js";
import { mapState } from "vuex";

export default {
  name: "NovoPost",

  props: {
    dialogPost: {
      type: Boolean,
      default: false,
    },
    edit: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
    },
  },

  data() {
    return {
      post: {
        status: 0,
        notificar: true,
        destaque: false,
      },
      thumbnail: null,
      thumbnailUpload: null,
      loading: false,
      hoverFile: false,
      validForm: true,
      post_original: {},
      formRules: [inputRequired],
      postInvalido: false,
    };
  },

  components: {
    vueDropzone: vue2Dropzone,
  },

  computed: {
    dropzoneOptions() {
      return {
        url: "/no-url",
        createfileThumbnails: false,
        acceptedFiles: ".png,.jpg,.jpeg",
        maxFilesize: 2,
        autoProcessQueue: false,
      };
    },

    ...mapState("Usuario", {
      user_id: (state) => state.usuario.id,
    }),
  },

  methods: {
    fileAdded(file) {
      this.thumbnailUpload = file;
      const vue = this;
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (event) => {
        vue.thumbnail = event.target.result;
      };
    },

    createPost() {
      if (this.post.conteudo || this.thumbnailUpload) {
        if (this.$refs.form.validate()) {
          this.loading = true;

          const post = new FormData();

          if (this.thumbnailUpload) {
            post.append(
              "thumbnail",
              this.thumbnailUpload,
              this.thumbnailUpload.name
            );
          }
          let post_2 = {};

          post_2.conteudo = this.post.conteudo;
          post_2.user_id = this.user_id;
          post_2.destaque = this.post.destaque;
          post_2.notificar = this.post.notificar;
          if (this.post.notificar === true) {
            post_2.notificar_texto = this.post.notificar_texto;

            const feed = new FormData();
            feed.append("title", "Não deixe passar!!!");
            feed.append("mensagem", this.post.notificar_texto);
            feed.append("user_id", this.user_id);
            postFeed(feed)
              .then((response) => {
                if (response.status === 201) {
                  this.$toast.success("Notificacao criada com sucesso");
                }
              })
              .catch(() => {
                this.loading = false;
              });
          }

          for (let key in post_2) {
            if (
              post_2[key] !== null &&
              post_2[key] !== undefined &&
              post_2[key] !== ""
            ) {
              post.append(key, post_2[key]);
            }
          }

          postPost(post)
            .then((response) => {
              if (response.status === 201) {
                this.$toast.success("Criado com sucesso");
                this.loading = false;
                this.$emit("update:dialogPost", false);
              }
            })
            .catch(() => {
              this.loading = false;
            });
        }
      } else {
        this.$toast.error(
          "Favor inserir uma mensagem ou uma imagem antes de postar"
        );
        this.postInvalido = true;
      }
    },

    updatePost() {
      if (this.post.conteudo || this.thumbnailUpload) {
        if (JSON.stringify(this.post) === JSON.stringify(this.post_original)) {
          this.$toast.error("Por favor, faça uma mudança antes de salvar!");
          return;
        }

        if (this.$refs.form.validate()) {
          this.loading = true;
          const post = new FormData();
          if (this.thumbnailUpload) {
            post.append(
              "thumbnail",
              this.thumbnailUpload,
              this.thumbnailUpload.name
            );
          }
          let post_2 = {};
          post_2.conteudo = this.post.conteudo;
          post_2.destaque = this.post.destaque;
          post_2.notificar = this.post.notificar;
          if (this.post.notificar === true) {
            post_2.notificar_texto = this.post.notificar_texto;
          }
          for (let key in post_2) {
            if (
              post_2[key] !== null &&
              post_2[key] !== undefined &&
              post_2[key] !== ""
            ) {
              post.append(key, post_2[key]);
            }
          }
          putPost(this.post.id, post)
            .then((response) => {
              if (response.status === 200) {
                this.$toast.success("Atualizado com sucesso");
                this.loading = false;
                this.$emit("update:dialogPost", false);
              }
            })
            .catch(() => {
              this.loading = false;
            });
        }
      } else {
        this.$toast.error(
          "Favor inserir uma mensagem ou uma imagem antes de postar"
        );
        this.postInvalido = true;
      }
    },
  },

  mounted() {
    if (this.edit) {
      this.post = { ...this.item };
      this.post_original = { ...this.item };
      this.thumbnail = this.item.thumbnail;
    }
  },
};
</script>

<style lang="scss" scoped>
.hoverFile {
  border-color: #2196f3 !important;
  color: #2196f3 !important;
}
#dropzone {
  border: 2px dashed #dddddd;
  padding: 16px;
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all ease-in-out 0.3s;

  &:hover {
    border-color: #2196f3;
    color: #2196f3;
  }

  ::v-deep .dz-preview {
    display: none !important;
  }
}
#dropzone-error {
  border: 2px dashed red;
  padding: 16px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all ease-in-out 0.3s;

  &:hover {
    border-color: #2196f3;
    color: #2196f3;
  }

  ::v-deep .dz-preview {
    display: none !important;
  }
}
.error-input {
  ::v-deep .theme--light.v-label {
    color: red;
  }
  ::v-deep .v-input__slot:before {
    border-color: rgb(255 0 0) !important;
  }
}
</style>
